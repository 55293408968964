.b,
.jobAdsPtublishedIn,
.stockholm {
  align-self: stretch;
  position: relative;
}
.stockholm {
  font-size: var(--h21-size);
  line-height: 134%;
  color: var(--font-color1);
}
.b,
.jobAdsPtublishedIn {
  line-height: 179%;
}
.jobAdsPtublishedIn {
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
  color: var(--font-color1);
}
.parent,
.stockholmParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent {
  align-self: stretch;
  border-radius: var(--br-xs);
  gap: var(--gap-xs);
}
.stockholmParent {
  flex: 1;
  gap: var(--gap-xl);
}
.mapaIcon {
  width: 326.13px;
  height: 727.66px;
  flex-shrink: 0;
  overflow: hidden;
}
.b8,
.mapaIcon,
.sverige {
  position: relative;
}
.sverige {
  font-size: var(--h21-size);
  line-height: 134%;
  display: flex;
  color: var(--font-color1);
  align-items: center;
  width: 135px;
}
.b8 {
  align-self: stretch;
  line-height: 179%;
  display: none;
}
.sverigeParent {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameParent {
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--h31-size);
  color: var(--tsl-green);
  font-family: var(--graph-text-121);
}
