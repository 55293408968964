.div {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.frameChild {
  position: relative;
  border-top: 1px solid var(--border);
  box-sizing: border-box;
  width: 9px;
  height: 1px;
  flex-shrink: 0;
}
.parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-md);
  align-items: flex-end;
  justify-content: space-between;
}
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--border);
  width: 1px;
  height: 208px;
}
.component80Inner {
  align-self: stretch;
  position: relative;
  width: 1px;
  flex-shrink: 0;
}
.component80 {
  height: 237px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameChild2 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--tsl-green);
  width: 24px;
  flex-shrink: 0;
}
.component80Child {
  flex: 1;
  border-bottom: 1px solid var(--border);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.component801,
.component81,
.component82,
.component83,
.component84,
.component85,
.component86,
.component88 {
  height: 194px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.component81,
.component82,
.component83,
.component84,
.component85,
.component86,
.component88 {
  height: 166px;
}
.component82,
.component83,
.component84,
.component85,
.component86,
.component88 {
  height: 222px;
}
.component83,
.component84,
.component85,
.component86,
.component88 {
  height: 116px;
}
.component84,
.component85,
.component86,
.component88 {
  height: 202px;
}
.component85,
.component86,
.component88 {
  height: 209px;
}
.component86,
.component88 {
  height: 180px;
}
.component88 {
  height: 223px;
}
.component80Parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
