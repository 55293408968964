.groupChild,
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 586px;
  height: 586px;
}
.groupItem {
  width: 290.67px;
  height: 290.67px;
  opacity: 0.8;
}
.dinFramtid {
  position: absolute;
  top: 158px;
  left: 119px;
  line-height: 179%;
}
.ellipseGroup {
  position: absolute;
  top: 154.77px;
  left: 179px;
  width: 290.67px;
  height: 290.67px;
}
.dittYrke {
  position: absolute;
  top: 158px;
  left: 54px;
  line-height: 179%;
}
.ellipseContainer {
  position: absolute;
  top: 154.77px;
  left: 0;
  width: 290.67px;
  height: 290.67px;
}
.din {
  margin-block-start: 0;
  margin-block-end: 0;
}
.utveckling {
  margin: 0;
}
.dinUtveckling {
  position: absolute;
  top: 57.77px;
  left: 77.5px;
  line-height: 179%;
}
.groupChild1,
.groupDiv {
  position: absolute;
  top: 0;
  left: 88.5px;
  width: 290.67px;
  height: 290.67px;
}
.groupChild1 {
  left: 0;
  width: 120.92px;
  height: 120.92px;
}
.you {
  position: absolute;
  top: 43.02px;
  left: 36.53px;
  line-height: 179%;
}
.ellipseParent1 {
  position: absolute;
  top: 179.06px;
  left: 174px;
  width: 120.92px;
  height: 120.92px;
  color: var(--font-color1);
}
.groupParent {
  position: absolute;
  top: 80.23px;
  left: 58px;
  width: 469.67px;
  height: 445.45px;
}
.dinJobbframtid {
  position: absolute;
  top: 31.15px;
  left: 190px;
  line-height: 179%;
  color: var(--font-color1);
  text-align: left;
}
.ellipseParent {
  position: relative;
  width: 586px;
  height: 586px;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--h31-size);
  color: var(--white);
  font-family: var(--graph-text-121);
}
