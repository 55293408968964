.frameChild {
  position: relative;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.dittYrke {
  position: relative;
  line-height: 179%;
}
.ellipseParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.loremIpsumDolor {
  align-self: stretch;
  position: relative;
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
  text-align: left;
}
.frameParent {
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
  text-align: center;
  font-size: var(--h31-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
