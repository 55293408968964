.enReflektionAv,
.loremIpsumDolor {
  position: relative;
  line-height: 134%;
  display: inline-block;
  width: 681px;
}
.loremIpsumDolor {
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.enReflektionAvDeInsikternaParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  font-size: var(--h21-size);
}
.overview {
  align-self: stretch;
  position: relative;
  line-height: 156%;
}
.mapaIcon {
  position: relative;
  width: 106px;
  height: 237px;
  flex-shrink: 0;
  overflow: hidden;
}
.overviewParent {
  width: 236px;
  height: 281px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.div,
.efterfrganVerTid {
  position: relative;
  line-height: 156%;
}
.div {
  line-height: 163%;
  font-weight: 500;
}
.frameChild {
  position: relative;
  border-top: 1px solid var(--border);
  box-sizing: border-box;
  width: 9px;
  height: 1px;
  flex-shrink: 0;
}
.parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
}
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-md);
  align-items: flex-end;
  justify-content: space-between;
}
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--border);
  width: 1px;
  height: 208px;
}
.component80Inner {
  align-self: stretch;
  position: relative;
  width: 1px;
  flex-shrink: 0;
}
.component80 {
  height: 237px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameChild2 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--tsl-green);
  width: 24px;
  flex-shrink: 0;
}
.component85Inner {
  flex: 1;
  border-bottom: 1px solid var(--border);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-2xs);
  align-items: center;
  justify-content: center;
}
.component85,
.component86,
.component88,
.component89 {
  height: 209px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.component86,
.component88,
.component89 {
  height: 180px;
}
.component88,
.component89 {
  height: 223px;
}
.component89 {
  height: 194px;
}
.component80Parent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  text-align: left;
  font-size: var(--graph-text-121-size);
}
.efterfrganVerTidParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.specifika {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 163%;
  font-weight: 500;
  display: inline-block;
  width: 236px;
}
.component32Child {
  position: absolute;
  height: 96.55%;
  width: 100%;
  top: 2.2%;
  right: 0;
  bottom: 1.25%;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
}
.skillName {
  position: absolute;
  right: 5.5px;
  bottom: 4.64px;
  line-height: 163%;
  font-weight: 500;
}
.component32 {
  position: relative;
  border-radius: var(--br-xs);
  width: 236px;
  height: 29px;
  flex-shrink: 0;
  overflow: hidden;
}
.component33Child {
  position: absolute;
  height: 96.55%;
  width: 100%;
  top: 2.2%;
  right: 0;
  bottom: 1.25%;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.95;
}
.component33 {
  position: relative;
  border-radius: var(--br-xs);
  width: 175px;
  height: 29px;
  flex-shrink: 0;
  overflow: hidden;
}
.component34Child {
  position: absolute;
  height: 96.55%;
  width: 100%;
  top: 2.2%;
  right: 0;
  bottom: 1.25%;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.9;
}
.component34 {
  position: relative;
  border-radius: var(--br-xs);
  width: 162px;
  height: 29px;
  flex-shrink: 0;
  overflow: hidden;
}
.component32Parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.groupChild,
.parent2 {
  position: absolute;
  top: -6.27px;
  left: 0;
  width: 235.15px;
  height: 12.53px;
}
.parent2 {
  top: 4.81px;
  width: 236px;
  height: 14.75px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.vectorParent {
  position: relative;
  width: 236px;
  height: 19.56px;
  flex-shrink: 0;
  font-size: var(--font-size-2xs);
  color: var(--placeholder-grey);
}
.frameParent2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
}
.frameWrapper {
  position: absolute;
  top: 37px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--graph-text-121-size);
}
.specifikaParent {
  position: relative;
  width: 236px;
  height: 155.56px;
  flex-shrink: 0;
}
.component32Item {
  position: absolute;
  height: 96.55%;
  width: 100%;
  top: 1.45%;
  right: 0;
  bottom: 2%;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
}
.skillName3 {
  position: absolute;
  right: -58.5px;
  bottom: 4.42px;
  line-height: 163%;
  font-weight: 500;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.component33Item {
  position: absolute;
  height: 96.55%;
  width: 100%;
  top: 1.45%;
  right: 0;
  bottom: 2%;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.95;
}
.component331 {
  position: relative;
  border-radius: var(--br-xs);
  width: 150px;
  height: 29px;
  flex-shrink: 0;
  overflow: hidden;
}
.component34Item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.9;
}
.skillName5 {
  position: absolute;
  right: -58.5px;
  bottom: 4px;
  line-height: 163%;
  font-weight: 500;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.component341 {
  position: relative;
  border-radius: var(--br-xs);
  width: 114px;
  height: 28px;
  flex-shrink: 0;
  overflow: hidden;
}
.component32Group {
  width: 236px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.groupItem {
  position: absolute;
  top: -6.27px;
  left: -235.15px;
  width: 235.15px;
  height: 12.53px;
}
.div15 {
  position: relative;
  line-height: 163%;
  font-weight: 500;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.frameParent3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
}
.frameWrapper1 {
  position: absolute;
  top: 37px;
  left: 236px;
  width: 236px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  transform: rotate(180deg);
  transform-origin: 0 0;
  text-align: left;
  font-size: var(--graph-text-121-size);
}
.generellaParent {
  position: relative;
  width: 236px;
  height: 154.56px;
  flex-shrink: 0;
}
.yourSkillsParent {
  align-self: stretch;
  width: 236px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}
.kompetens {
  flex: 1;
}
.div20,
.efterfrgan,
.kompetens {
  position: relative;
  line-height: 163%;
}
.div20 {
  align-self: stretch;
  font-size: var(--preamble1-size);
  font-weight: 500;
}
.efterfrganParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.kompetensParent {
  align-self: stretch;
  background-color: var(--background-grey);
  border-bottom: 1px solid var(--border);
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-2xs);
  align-items: center;
  justify-content: center;
  text-align: center;
}
.efterfrganWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.kompetensGroup {
  align-self: stretch;
  background-color: var(--background-grey);
  border: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-2xs);
  align-items: center;
  justify-content: center;
  text-align: center;
}
.loremIpsum,
.na {
  flex: 1;
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.na {
  text-align: center;
}
.frameChild7,
.frameChild8 {
  align-self: stretch;
  position: relative;
  flex-shrink: 0;
}
.frameChild7 {
  background-color: var(--border);
  width: 1px;
}
.frameChild8 {
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--blue1);
  height: 26px;
}
.rectangleParent,
.rectangleWrapper {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs) 0;
}
.rectangleParent {
  align-self: stretch;
  display: none;
  flex-direction: row;
}
.component78Icon {
  position: relative;
  width: 44px;
  height: 24px;
  flex-shrink: 0;
}
.component78Wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs) 0;
  align-items: center;
  justify-content: center;
}
.component51,
.naWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.component51 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--border);
  padding: 0 var(--padding-2xs);
  align-items: center;
}
.naWrapper {
  flex: 1;
  padding: var(--padding-2xs) 0;
  align-items: flex-start;
  text-align: center;
}
.frameChild10,
.frameChild9 {
  position: relative;
  background-color: var(--border);
  width: 1px;
  height: 42px;
  flex-shrink: 0;
}
.frameChild10 {
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--blue1);
  width: 146px;
  height: 26px;
}
.rectangleContainer,
.rectangleGroup {
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleContainer {
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs) 0;
}
.rectangleGroup {
  flex: 1;
  display: none;
  flex-direction: row;
}
.component511,
.frameParent5 {
  align-self: stretch;
  display: flex;
}
.component511 {
  background-color: var(--background-grey);
  border-bottom: 1px solid var(--border);
  flex-direction: row;
  padding: 0 var(--padding-2xs);
  align-items: center;
  justify-content: center;
}
.frameParent5 {
  border-radius: var(--br-xs);
  border: 1px solid var(--border);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--button-text-size);
}
.kompetensContainer,
.specifikaGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.specifikaGroup {
  width: 370px;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-xs);
}
.kompetensContainer {
  align-self: stretch;
  background-color: var(--background-grey);
  border-bottom: 1px solid var(--border);
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-2xs);
  text-align: center;
}
.frameParent6,
.generellaGroup {
  display: flex;
  flex-direction: column;
}
.frameParent6 {
  align-self: stretch;
  border-radius: var(--br-xs);
  border: 1px solid var(--border);
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--button-text-size);
}
.generellaGroup {
  width: 370px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  text-align: center;
}
.frameParent,
.frameParent4,
.skillChangesParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent4 {
  flex-direction: row;
  gap: var(--gap-lg);
  text-align: left;
}
.frameParent,
.skillChangesParent {
  flex-direction: column;
  gap: var(--gap-sm);
}
.frameParent {
  width: 1036px;
  justify-content: center;
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
