.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.kr {
  position: relative;
  line-height: 134%;
}
.krWrapper {
  border-radius: var(--br-xs);
  width: 251px;
  height: 61.5px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.thisIsThe {
  flex: 1;
  position: relative;
  line-height: 156%;
}
.thisIsTheMontlyAmountYouWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--preamble1-size);
}
.frameContainer {
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  width: 502px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  font-size: var(--h1-size);
  color: var(--white);
}
.ekonomisktStdFr {
  margin-block-start: 0;
  margin-block-end: 0;
}
.dagarEllerMer {
  margin: 0;
}
.ekonomisktStdFrContainer {
  position: relative;
  line-height: 134%;
  display: inline-block;
  width: 598px;
}
.perMnad {
  align-self: stretch;
  position: relative;
  font-size: var(--h31-size);
  line-height: 179%;
  text-align: center;
}
.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--green-3);
  width: 532.8px;
  height: 42px;
}
.b {
  position: relative;
  line-height: 163%;
}
.omstllningsstudiebidragFrnParent {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  width: 592px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
}
.rectangleParent {
  align-self: stretch;
  position: relative;
  height: 45px;
  flex-shrink: 0;
}
.groupInner,
.groupItem,
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--green-3);
  width: 355.2px;
  height: 42px;
  opacity: 0.9;
}
.groupInner,
.rectangleDiv {
  width: 295.58px;
  opacity: 0.8;
}
.rectangleDiv {
  width: 118.4px;
  opacity: 0.7;
}
.groupParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.b4 {
  position: relative;
  font-size: var(--h31-size);
  line-height: 179%;
}
.perMnadParent,
.totalParent {
  display: flex;
  gap: var(--gap-sm);
}
.totalParent {
  align-self: stretch;
  border-radius: 0 var(--br-xs) var(--br-xs) var(--br-xs);
  flex-direction: row;
  padding: 0 var(--padding-sm);
  align-items: center;
  justify-content: flex-end;
}
.perMnadParent {
  width: 592px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.groupChild1,
.omstllningsstudiebidragFrnParent2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
}
.groupChild1 {
  background-color: var(--green-3);
  width: 12.41px;
  height: 42px;
}
.omstllningsstudiebidragFrnParent2 {
  width: 414px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-sm);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
}
.groupWrapper {
  align-self: stretch;
  flex-direction: column;
}
.frameDiv,
.groupWrapper,
.perDagParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.perDagParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-sm);
}
.frameDiv {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--preamble1-size);
}
.braAttVeta {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.viInformerarDigContainer {
  flex: 1;
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.braAttVetaParent {
  border-radius: var(--br-xs);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.braAttVetaParent,
.frameGroup,
.groupGroup {
  align-self: stretch;
  display: flex;
}
.frameGroup {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.groupGroup {
  background-color: var(--white);
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
}
.div,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameParent {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
  font-size: var(--h21-size);
  color: var(--font-color1);
}
.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  cursor: pointer;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
