@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --graph-text-121: Montserrat;

  /* font sizes */
  --font-size-2xs: 8px;
  --graph-text-121-size: 12px;
  --button-text-size: 16px;
  --preamble1-size: 18px;
  --h31-size: 24px;
  --h21-size: 32px;
  --h1-size: 44px;
  --font-size-3xl: 64px;
  --font-size-4xl: 80px;

  /* Colors */
  --white: #fff;
  --placeholder-grey: #858585;
  --font-color1: #22262f;
  --black: #141414;
  --warning-red1: #eb3c27;
  --yellow1: #ffb501;
  --blue1: #006293;
  --color-steelblue-200: rgba(0, 98, 147, 0.35);
  --background-green1: #e2edf0;
  --border: #d3d3d4;
  --chapter-2: #5f4a8b;
  --tsl-green: #83bb25;
  --background-grey: #f5f3ee;
  --graph-extra-color-10: #cbc039;
  --graph-extra-color-2: #008485;
  --chapter-1: #a81d2a;
  --green-3: #b6dd7a;
  --chapter-3: #304f40;

  /* Gaps */
  --gap-3xs: 1.82px;
  --gap-2xs: 4px;
  --gap-xs: 8px;
  --gap-sm: 16px;
  --gap-md: 24px;
  --gap-lg: 30px;
  --gap-xl: 32px;
  --gap-2xl: 40px;
  --gap-3xl: 48px;

  /* Paddings */
  --padding-5xs: 1.819498062133789px;
  --padding-4xs: 2px;
  --padding-3xs: 4px;
  --padding-2xs: 8px;
  --padding-xs: 12px;
  --padding-sm: 16px;
  --padding-md: 22.5px;
  --padding-lg: 28px;
  --padding-xl: 32px;
  --padding-2xl: 36px;
  --padding-3xl: 40px;
  --padding-4xl: 165px;

  /* border radiuses */
  --br-2xs: 2px;
  --br-xs: 4px;
  --br-sm: 8px;
  --br-md: 130px;
  --br-lg: 200px;
  --br-xl: 400px;
}
