.efterfrganVerTid,
.hrSerDu {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.hrSerDu {
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.efterfrganVerTidParent,
.frameParent {
  display: flex;
  justify-content: center;
  gap: var(--gap-xl);
}
.efterfrganVerTidParent {
  width: 414px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-size: var(--h21-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
