.groupChild {
  position: absolute;
  top: 15.95px;
  left: 0;
  width: 414.5px;
  height: 22.09px;
}
.div {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.parent {
  position: absolute;
  top: 39px;
  left: 0;
  width: 416px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.efterfrgan {
  position: absolute;
  top: 0;
  left: 306px;
  line-height: 163%;
  font-weight: 500;
}
.vectorParent {
  position: relative;
  width: 416px;
  height: 65px;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
