.frameChild {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--blue1);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.dinFramtid {
  position: relative;
  line-height: 134%;
}
.frameParent {
  border-radius: var(--br-sm) var(--br-sm) 0 0;
  background-color: var(--chapter-2);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.viHarNu {
  flex: 1;
  position: relative;
  line-height: 163%;
}
.component76,
.viHarNuKommitTillSlutetAWrapper {
  border-radius: var(--br-sm);
  display: flex;
  align-items: center;
}
.viHarNuKommitTillSlutetAWrapper {
  align-self: stretch;
  border: 1px solid var(--border);
  flex-direction: row;
  padding: var(--padding-sm);
  justify-content: flex-start;
  text-align: left;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
}
.component76 {
  width: 1036px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--white);
  font-family: var(--graph-text-121);
}
