.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  cursor: pointer;
}
