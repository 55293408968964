.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent {
  background-color: var(--white);
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameChild {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--blue1);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.dinFramtid {
  position: relative;
  line-height: 134%;
}
.frameGroup {
  border-radius: var(--br-sm) var(--br-sm) 0 0;
  background-color: var(--chapter-3);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.viHarNu {
  flex: 1;
  position: relative;
  line-height: 163%;
}
.viHarNuKommitTillSlutetAWrapper {
  align-self: stretch;
  border-radius: var(--br-sm);
  border: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--button-text-size);
  color: var(--font-color1);
}
.component76,
.instanceParent {
  display: flex;
  flex-direction: column;
}
.component76 {
  border-radius: var(--br-sm);
  width: 1036px;
  align-items: center;
  justify-content: center;
  font-size: var(--h1-size);
  color: var(--white);
}
.instanceParent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.frameItem {
  position: absolute;
  height: 21.59%;
  width: 22.01%;
  top: 66.29%;
  right: -7.72%;
  bottom: 12.12%;
  left: 85.71%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.componentChild {
  position: absolute;
  height: 100%;
  width: 105.26%;
  top: 0;
  right: -5.26%;
  bottom: 0;
  left: 0;
  background-color: var(--background-green1);
}
.componentItem,
.rectangleWrapper {
  position: absolute;
  top: 178px;
  left: 619px;
  width: 209px;
  height: 16px;
}
.componentItem {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-green1);
}
.rectangleContainer {
  position: absolute;
  top: 488px;
  left: 115px;
  width: 724px;
  height: 16px;
}
.frameInner,
.rectangleDiv,
.rectangleFrame {
  position: absolute;
  top: 700px;
  left: 115px;
  width: 773px;
  height: 16px;
}
.frameInner,
.rectangleDiv {
  top: 912px;
}
.frameInner {
  top: 151.36px;
  left: 749px;
  width: 60px;
  height: 69.28px;
}
.arrowIcon,
.ellipseIcon,
.frameChild1,
.frameChild2,
.frameChild3,
.frameChild4,
.frameChild5 {
  position: absolute;
  height: 6.56%;
  width: 5.79%;
  top: 43.69%;
  right: 21.91%;
  bottom: 49.75%;
  left: 72.3%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.ellipseIcon,
.frameChild1,
.frameChild2,
.frameChild3,
.frameChild4,
.frameChild5 {
  right: 83.11%;
  left: 11.1%;
}
.ellipseIcon,
.frameChild2,
.frameChild3,
.frameChild4,
.frameChild5 {
  top: 63.77%;
  right: 86.58%;
  bottom: 29.67%;
  left: 7.63%;
}
.ellipseIcon,
.frameChild3,
.frameChild4,
.frameChild5 {
  height: 5.68%;
  width: 6.69%;
  top: 74.24%;
  right: 0.81%;
  bottom: 20.08%;
  left: 92.51%;
}
.ellipseIcon,
.frameChild4,
.frameChild5 {
  height: 6.56%;
  width: 5.79%;
  top: 83.84%;
  right: 63.9%;
  bottom: 9.6%;
  left: 30.31%;
}
.ellipseIcon,
.frameChild5 {
  height: 30.87%;
  width: 31.47%;
  top: 16.86%;
  right: -12.45%;
  bottom: 52.27%;
  left: 80.98%;
}
.frameChild5 {
  height: 21.59%;
  width: 11%;
  top: 46.21%;
  right: 88.9%;
  bottom: 32.2%;
  left: 0.1%;
}
.frameChild6,
.frameChild7,
.lineIcon {
  position: absolute;
  top: 241.39px;
  left: 427.81px;
  width: 36.19px;
  height: 27.61px;
}
.frameChild6,
.frameChild7 {
  left: 602px;
}
.frameChild7 {
  top: 103px;
}
.din {
  margin-block-start: 0;
  margin-block-end: 0;
}
.jobbframtidAnalys {
  margin: 0;
}
.dinJobbframtidAnalysContainer {
  flex: 1;
  position: relative;
  line-height: 156%;
}
.component90,
.dinJobbframtidAnalysWrapper {
  position: absolute;
  top: 96px;
  left: 443px;
  border-radius: var(--br-lg);
  background-color: var(--tsl-green);
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.component90 {
  top: 406px;
  left: 324px;
  background-color: var(--background-green1);
}
.dinJobbframtidAnalys {
  flex: 1;
  position: relative;
  line-height: 134%;
}
.component91 {
  position: absolute;
  top: 263px;
  left: 891px;
  border-radius: var(--br-lg);
  background-color: var(--background-green1);
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--h1-size);
}
.component90frame299Child {
  position: relative;
  width: 87.54px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
}
.component90frame299Inner,
.component90frame299Item,
.maskGroupIcon {
  position: relative;
  width: 99.77px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.component90frame299Inner,
.maskGroupIcon {
  width: 68.51px;
}
.component90frame299Inner {
  width: 99.62px;
}
.component90frame299 {
  position: absolute;
  top: 502px;
  left: 927px;
  border-radius: var(--br-md);
  background-color: var(--white);
  border: 12px solid var(--background-green1);
  box-sizing: border-box;
  width: 140px;
  height: 140px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: center;
}
.checkIcon {
  position: relative;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  overflow: hidden;
}
.nyttJobb {
  align-self: stretch;
  position: relative;
  line-height: 156%;
}
.component901 {
  position: absolute;
  top: 830px;
  left: 45px;
  border-radius: var(--br-lg);
  background-color: var(--green-3);
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.dittYrke {
  position: relative;
  line-height: 163%;
}
.dittYrkeWrapper {
  border-radius: var(--br-md);
  background-color: var(--chapter-1);
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.loremIpsumDolor {
  align-self: stretch;
  position: relative;
  line-height: 163%;
  font-weight: 500;
  color: var(--font-color1);
  text-align: left;
  display: inline-block;
  width: 240px;
  flex-shrink: 0;
}
.frameContainer {
  position: absolute;
  top: -2px;
  left: 623px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-xs);
  font-size: var(--button-text-size);
  color: var(--white);
}
.loremIpsumDolor1 {
  align-self: stretch;
  position: relative;
  line-height: 163%;
  font-weight: 500;
  display: inline-block;
  width: 240px;
  flex-shrink: 0;
}
.dittYrkeContainer {
  border-radius: var(--br-md);
  background-color: var(--graph-extra-color-2);
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  padding: var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  color: var(--white);
}
.dittYrkeContainer,
.dittYrkeFrame,
.loremIpsumDolorSitAmetCoParent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.loremIpsumDolorSitAmetCoParent {
  position: absolute;
  top: 244px;
  left: 65px;
  overflow: hidden;
  align-items: flex-end;
  gap: var(--gap-xs);
  text-align: right;
  font-size: var(--button-text-size);
}
.dittYrkeFrame {
  border-radius: var(--br-md);
  background-color: var(--graph-extra-color-10);
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  padding: var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
}
.loremIpsumDolor2 {
  align-self: stretch;
  position: relative;
  line-height: 163%;
  font-weight: 500;
  text-align: left;
  display: none;
  width: 240px;
  flex-shrink: 0;
}
.frameDiv {
  position: absolute;
  top: 244px;
  left: 623px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-xs);
  font-size: var(--button-text-size);
}
.ellipseParent {
  flex: 1;
  position: relative;
  width: 1036px;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
}
.frameParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.div,
.groupParent {
  background-color: var(--white);
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.div {
  position: relative;
  width: 100%;
  height: 1933px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  cursor: pointer;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
