.groupChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.dinJobbframtid,
.viHoppasAtt {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.viHoppasAtt {
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.dinJobbframtidParent {
  position: absolute;
  width: 68.62%;
  top: calc(50% - 369.5px);
  right: 15.69%;
  left: 15.69%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.ellipseParent {
  position: absolute;
  height: 46.14%;
  width: 65.23%;
  top: 26.95%;
  right: 17.42%;
  bottom: 26.91%;
  left: 17.35%;
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--white);
  font-family: var(--graph-text-121);
}
