.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent,
.groupParent {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dinJobbframtidAnalysisParent {
  align-self: stretch;
  align-items: center;
}
.groupParent {
  width: 1036px;
  align-items: flex-end;
}
.div,
.frameParent {
  display: flex;
  flex-direction: column;
}
.frameParent {
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
}
.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  cursor: pointer;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
