.malePainterDressedHazmatSuIcon {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  width: 185px;
  height: 487px;
  object-fit: cover;
}
.lagerOchTerminalpersonal {
  position: absolute;
  width: 183.61%;
  top: 92.63%;
  left: 13.41%;
  line-height: 179%;
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: 0 0;
}
.malePainterDressedHazmatSuParent {
  position: relative;
  width: 185px;
  height: 487px;
  flex-shrink: 0;
}
.component32Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
}
.skillName {
  position: absolute;
  right: 8px;
  bottom: 0;
  line-height: 163%;
  font-weight: 500;
}
.component32 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs);
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component33Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.95;
}
.component33 {
  position: relative;
  border-radius: var(--br-xs);
  width: 383px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component34Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.9;
}
.component34 {
  position: relative;
  border-radius: var(--br-xs);
  width: 367px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component35Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.85;
}
.component35 {
  position: relative;
  border-radius: var(--br-xs);
  width: 351px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component45Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.8;
}
.component45 {
  position: relative;
  border-radius: var(--br-xs);
  width: 347px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component44Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.75;
}
.component44 {
  position: relative;
  border-radius: var(--br-xs);
  width: 343px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component43Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.7;
}
.component43 {
  position: relative;
  border-radius: var(--br-xs);
  width: 319px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component42Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.65;
}
.component42 {
  position: relative;
  border-radius: var(--br-xs);
  width: 303px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component41Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.6;
}
.component41 {
  position: relative;
  border-radius: var(--br-xs);
  width: 279px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component46Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.55;
}
.component46 {
  position: relative;
  border-radius: var(--br-xs);
  width: 231px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component47Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.5;
}
.component47 {
  position: relative;
  border-radius: var(--br-xs);
  width: 191px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component32Parent,
.frameGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.component32Parent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.frameGroup {
  flex: 1;
  justify-content: center;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
}
.groupParent {
  width: 603px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.specifikaKompetenser {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.loremIpsumDolor {
  margin-block-start: 0;
  margin-block-end: 0;
}
.loremIpsumDolor1 {
  margin: 0;
}
.loremIpsumDolorContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.frameParent,
.specifikaKompetenserParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.specifikaKompetenserParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
  font-size: var(--h21-size);
  color: var(--font-color1);
}
.frameParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--h31-size);
  color: var(--white);
  font-family: var(--graph-text-121);
}
