.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameChild {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--blue1);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.dinFramtid {
  position: relative;
  line-height: 134%;
}
.frameParent {
  border-radius: var(--br-sm) var(--br-sm) 0 0;
  background-color: var(--chapter-3);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nuDuKan {
  margin-block-start: 0;
  margin-block-end: 0;
}
.viHarNu {
  margin: 0;
}
.viHarNuContainer {
  flex: 1;
  position: relative;
  line-height: 163%;
}
.component76,
.viHarNuKommitTillSlutetAWrapper {
  border-radius: var(--br-sm);
  display: flex;
  align-items: center;
}
.viHarNuKommitTillSlutetAWrapper {
  align-self: stretch;
  border: 1px solid var(--border);
  flex-direction: row;
  padding: var(--padding-sm);
  justify-content: flex-start;
  text-align: left;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
}
.component76 {
  width: 1036px;
  flex-direction: column;
  justify-content: center;
}
.loremIpsumDolor,
.yourNextStep {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.loremIpsumDolor {
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.frameDiv,
.yourNextStepParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.yourNextStepParent {
  width: 502px;
  align-items: center;
  gap: var(--gap-sm);
}
.frameDiv {
  align-items: flex-start;
  gap: var(--gap-md);
}
.image6Icon {
  position: relative;
  border-radius: var(--br-xs);
  width: 121.61px;
  height: 120px;
  flex-shrink: 0;
  object-fit: cover;
}
.toBookA {
  color: var(--white);
}
.tslsedinJobbframtidContact {
  text-decoration: underline;
}
.toBookAContainer {
  position: relative;
  line-height: 163%;
  display: inline-block;
  width: 502px;
  flex-shrink: 0;
}
.frameContainer,
.image6Parent {
  display: flex;
  align-items: center;
}
.image6Parent {
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  border: 4px solid var(--tsl-green);
  flex-direction: row;
  padding: 0 var(--padding-sm) 0 0;
  justify-content: center;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--preamble1-size);
}
.frameContainer {
  height: 1056px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameGroup,
.groupParent {
  display: flex;
  justify-content: space-between;
}
.groupParent {
  background-color: var(--white);
  width: 1036px;
  flex-direction: row;
  align-items: flex-end;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
}
.frameGroup {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--h21-size);
  color: var(--font-color1);
}
.component76Parent {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-xl);
}
.component76Parent,
.div,
.inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.inner {
  flex: 1;
  align-items: flex-start;
  font-size: var(--h1-size);
  color: var(--white);
}
.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
