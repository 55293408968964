.dinJobbframtidAnalysis {
  position: relative;
  line-height: 134%;
  display: inline-block;
  width: 726px;
}
.fullName {
  font-size: var(--font-size-3xl);
  color: var(--white);
}
.fullName,
.job {
  position: relative;
  line-height: 134%;
}
.frameChild {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-2xs);
  background-color: var(--white);
  width: 4px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent,
.jobParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--h21-size);
}
.dinJobbframtidAnalysisParent {
  position: absolute;
  height: 38.43%;
  width: 68.62%;
  top: 30.78%;
  right: 6.99%;
  bottom: 30.78%;
  left: 24.39%;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-4xl);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
