.frndringstaktYrke,
.hrSerDu {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.hrSerDu {
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.frndringstaktYrkeParent {
  width: 414px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.balance {
  position: absolute;
  top: 0;
  left: 257px;
  line-height: 163%;
}
.low {
  margin-block-start: 0;
  margin-block-end: 0;
}
.change {
  margin: 0;
}
.highChange,
.lowChange {
  position: absolute;
  top: 167px;
  left: 0;
  line-height: 163%;
}
.highChange {
  left: 519px;
}
.frameChild {
  position: absolute;
  top: 45px;
  left: 115px;
  width: 360px;
  height: 360px;
}
.balanceParent {
  position: relative;
  width: 590px;
  height: 233px;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--preamble1-size);
}
.frameParent {
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--h21-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
