.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent {
  background-color: var(--white);
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.instanceParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
