.frndringstaktYrke {
  align-self: stretch;
  position: relative;
  line-height: 156%;
}
.frameChild {
  position: relative;
  width: 236px;
  height: 123.38px;
  flex-shrink: 0;
}
.frameWrapper,
.frndringstaktYrkeParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.frameWrapper {
  flex: 1;
  flex-direction: row;
}
.frndringstaktYrkeParent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-sm);
  text-align: center;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
