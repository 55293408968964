.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dinJobbframtidAnalysis1 {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.dinJobbframtidAnalysisGroup,
.instanceParent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
}
.dinJobbframtidAnalysisGroup {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  font-size: var(--h1-size);
  color: var(--font-color1);
}
.instanceParent {
  align-items: flex-start;
  justify-content: flex-start;
}
.groupParent {
  background-color: var(--white);
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.div,
.frameParent {
  display: flex;
  flex-direction: column;
}
.frameParent {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-3xl);
  cursor: pointer;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
