.instanceChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-sm);
}
.checkIcon {
  position: absolute;
  height: 80%;
  width: 80%;
  top: 10%;
  right: 10%;
  bottom: 10%;
  left: 10%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.rectangleParent {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.loremIpsum {
  position: relative;
  line-height: 179%;
  display: inline-block;
  width: 497px;
}
.loremIpsumDolor {
  align-self: stretch;
  position: relative;
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.loremIpsumParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.instanceGroup,
.instanceParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.instanceGroup {
  width: 503px;
  flex-shrink: 0;
  align-items: flex-start;
  gap: var(--gap-xs);
}
.instanceParent {
  align-items: center;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--h31-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
