.generellaKompetenser {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.loremIpsumDolor {
  margin-block-start: 0;
  margin-block-end: 0;
}
.loremIpsumDolor1 {
  margin: 0;
}
.loremIpsumDolorContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.generellaKompetenserParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.component32Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
}
.skillName {
  position: absolute;
  top: calc(50% - 13px);
  left: 8px;
  line-height: 163%;
  font-weight: 500;
}
.component32 {
  align-self: stretch;
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component33Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.95;
}
.component33 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 383px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component34Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.9;
}
.component34 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 367px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component35Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.85;
}
.component35 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 351px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component45Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.8;
}
.component45 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 347px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component44Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.75;
}
.component44 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 343px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component43Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.7;
}
.component43 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 319px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component42Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.65;
}
.component42 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 303px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component41Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.6;
}
.component41 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 279px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component46Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.55;
}
.component46 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 231px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component47Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.5;
}
.component47 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 191px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component48Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.45;
}
.component48 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 159px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component49Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.4;
}
.component49 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 143px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component50Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  background-color: var(--tsl-green);
  opacity: 0.35;
}
.component50 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  width: 127px;
  height: 34px;
  flex-shrink: 0;
  overflow: hidden;
}
.component32Parent,
.frameDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.component32Parent {
  width: 411px;
  align-items: flex-end;
  gap: var(--gap-2xs);
}
.frameDiv {
  align-items: flex-start;
}
.malePainterDressedHazmatSuIcon {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-xs);
  width: 180.17px;
  height: 601px;
  object-fit: cover;
}
.lagerOchTerminalpersonal {
  position: absolute;
  height: 7.15%;
  width: 178.79%;
  top: 96.78%;
  left: 12.77%;
  line-height: 179%;
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: 0 0;
}
.malePainterDressedHazmatSuParent {
  position: relative;
  width: 180.17px;
  height: 601px;
  flex-shrink: 0;
  font-size: var(--h31-size);
  color: var(--white);
}
.frameContainer,
.frameGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.frameContainer {
  align-items: flex-end;
  gap: var(--gap-xs);
  font-size: var(--preamble1-size);
}
.frameGroup {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-lg);
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.page1Of {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.frameParent,
.groupParent {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}
.groupParent {
  background-color: var(--white);
  flex-direction: row;
  align-items: flex-end;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
}
.frameParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: var(--h21-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
