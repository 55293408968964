.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.jobsRanking {
  align-self: stretch;
  position: relative;
  font-size: var(--h31-size);
  line-height: 179%;
}
.frameChild {
  position: relative;
  width: 44px;
  height: 24px;
  flex-shrink: 0;
}
.frameContainer,
.groupParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameContainer {
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-xl);
  text-align: left;
}
.occupation,
.ranking {
  position: relative;
  line-height: 163%;
}
.occupation {
  flex: 1;
}
.historical {
  margin-block-start: 0;
  margin-block-end: 0;
}
.demand {
  margin: 0;
}
.rankingParent {
  align-self: stretch;
  background-color: var(--background-grey);
  border: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) 0 var(--padding-4xs) var(--padding-2xs);
  align-items: center;
  justify-content: center;
  font-size: var(--button-text-size);
}
.b {
  position: relative;
  line-height: 163%;
  display: inline-block;
  width: 32px;
  flex-shrink: 0;
}
.listItemsInner,
.loremIpsumWrapper,
.wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  align-self: stretch;
  border: 1px solid var(--white);
  box-sizing: border-box;
  width: 87px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 var(--padding-sm);
}
.listItemsInner,
.loremIpsumWrapper {
  flex: 1;
}
.loremIpsumWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-2xs);
  text-align: left;
}
.listItemsInner {
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-lg);
}
.listItems,
.listItems1,
.listItems2,
.listItems3 {
  align-self: stretch;
  background-color: rgba(131, 187, 37, 0.6);
  border: 1px solid var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: -1px;
}
.listItems1,
.listItems2,
.listItems3 {
  background-color: rgba(131, 187, 37, 0.56);
}
.listItems2,
.listItems3 {
  background-color: rgba(131, 187, 37, 0.52);
}
.listItems3 {
  background-color: rgba(131, 187, 37, 0.48);
}
.listItems4 {
  background-color: rgba(131, 187, 37, 0.44);
}
.listItems10,
.listItems11,
.listItems12,
.listItems13,
.listItems14,
.listItems4,
.listItems5,
.listItems6,
.listItems7,
.listItems8,
.listItems9 {
  align-self: stretch;
  border: 1px solid var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: -1px;
}
.listItems5 {
  background-color: var(--color-steelblue-200);
}
.listItems10,
.listItems11,
.listItems12,
.listItems13,
.listItems14,
.listItems6,
.listItems7,
.listItems8,
.listItems9 {
  background-color: rgba(131, 187, 37, 0.36);
}
.listItems10,
.listItems11,
.listItems12,
.listItems13,
.listItems14,
.listItems7,
.listItems8,
.listItems9 {
  background-color: rgba(131, 187, 37, 0.32);
}
.listItems10,
.listItems11,
.listItems12,
.listItems13,
.listItems14,
.listItems8,
.listItems9 {
  background-color: rgba(131, 187, 37, 0.28);
}
.listItems10,
.listItems11,
.listItems12,
.listItems13,
.listItems14,
.listItems9 {
  background-color: rgba(131, 187, 37, 0.24);
}
.listItems10,
.listItems11,
.listItems12,
.listItems13,
.listItems14 {
  background-color: rgba(131, 187, 37, 0.2);
}
.listItems11,
.listItems12,
.listItems13,
.listItems14 {
  background-color: rgba(131, 187, 37, 0.16);
}
.listItems12,
.listItems13,
.listItems14 {
  background-color: rgba(131, 187, 37, 0.12);
}
.listItems13,
.listItems14 {
  background-color: rgba(131, 187, 37, 0.08);
}
.listItems14 {
  background-color: rgba(131, 187, 37, 0.04);
}
.frameParent1,
.jobsRankingParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.frameParent1 {
  border-radius: var(--br-xs);
  border: 1px solid var(--border);
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
}
.jobsRankingParent {
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}
.ellipseIcon {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.ellipseParent {
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameParent2,
.frameWrapper1,
.listItems15 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.frameWrapper1 {
  flex-direction: row;
  padding: 0 var(--padding-sm);
  justify-content: center;
}
.frameParent2,
.listItems15 {
  overflow: hidden;
}
.listItems15 {
  border-radius: var(--br-xs);
  background-color: var(--color-steelblue-200);
  border: 1px solid var(--border);
  flex-direction: row;
  justify-content: flex-start;
}
.frameParent2 {
  flex-direction: column;
  padding: var(--padding-2xs) 0;
  justify-content: center;
  gap: var(--gap-xs);
}
.frameGroup {
  width: 1036px;
  overflow: hidden;
}
.frameGroup,
.frameWrapper,
.groupGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.groupGroup {
  background-color: var(--white);
  width: 1036px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
}
.div,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameParent {
  flex: 1;
  justify-content: space-between;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
}
.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  cursor: pointer;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
