.frameChild {
  position: absolute;
  height: 107.69%;
  width: 31.16%;
  top: -1.57%;
  right: 22.74%;
  bottom: -6.12%;
  left: 46.1%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.5;
}
.frameInner,
.frameItem {
  position: absolute;
  top: 76px;
  left: 220px;
  width: 192px;
  height: 426.07px;
}
.frameInner {
  top: 499.63px;
}
.instanceChild {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--blue1);
  width: 8px;
  flex-shrink: 0;
  display: none;
}
.skills {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.rectangleParent,
.rectangleParent1,
.skillsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.skillsWrapper {
  padding: var(--padding-3xs) var(--padding-sm);
  align-items: flex-start;
}
.rectangleParent,
.rectangleParent1 {
  border-radius: var(--br-sm);
  background-color: var(--blue1);
  align-items: center;
  gap: var(--gap-xs);
}
.rectangleParent1 {
  background-color: var(--yellow1);
  color: var(--font-color1);
}
.instanceChild2 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--yellow1);
  width: 8px;
  flex-shrink: 0;
  display: none;
}
.instanceChild6,
.rectangleParent5 {
  background-color: var(--warning-red1);
}
.rectangleParent5 {
  border-radius: var(--br-sm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.instanceChild6 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs);
  width: 8px;
  flex-shrink: 0;
  display: none;
}
.instanceParent {
  position: absolute;
  top: 59px;
  left: 401px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xl);
  color: var(--white);
}
.sprinklermontr,
.yourJob {
  position: relative;
  line-height: 163%;
}
.sprinklermontr {
  font-size: var(--h31-size);
  line-height: 179%;
}
.yourJobParent {
  position: absolute;
  top: 445px;
  left: 0;
  border-radius: var(--br-sm);
  background-color: var(--background-green1);
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.component91Child {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--blue1);
  width: 8px;
  flex-shrink: 0;
}
.component91,
.relatedJobWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.relatedJobWrapper {
  padding: var(--padding-3xs) 0;
  align-items: flex-start;
}
.component91 {
  align-items: center;
  gap: var(--gap-xs);
}
.instanceChild13,
.instanceChild9 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--yellow1);
  width: 8px;
  flex-shrink: 0;
}
.instanceChild13 {
  background-color: var(--warning-red1);
}
.component91Parent {
  position: absolute;
  top: 59px;
  left: 799px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xl);
  text-align: right;
}
.occupation,
.skills12 {
  position: absolute;
  top: 0;
  left: 401px;
  font-size: var(--h31-size);
  line-height: 179%;
}
.occupation {
  left: 800px;
}
.groupParent {
  position: relative;
  width: 1036px;
  height: 965px;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
