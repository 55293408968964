.hrSerDu,
.prognos {
  align-self: stretch;
  position: relative;
  line-height: 134%;
}
.hrSerDu {
  font-size: var(--preamble1-size);
  line-height: 163%;
  font-weight: 500;
}
.prognosParent {
  width: 414px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.frameChild {
  position: absolute;
  top: 45px;
  left: 116px;
  width: 360px;
  height: 360px;
}
.balance {
  position: absolute;
  top: 0;
  left: 258px;
  line-height: 163%;
}
.low {
  margin-block-start: 0;
  margin-block-end: 0;
}
.possibility {
  margin: 0;
}
.highPossibility,
.lowPossibility {
  position: absolute;
  top: 167px;
  left: 0;
  line-height: 163%;
}
.highPossibility {
  left: 496px;
}
.groupParent {
  position: relative;
  width: 592px;
  height: 233px;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--preamble1-size);
}
.frameGroup {
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.page1Of {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.frameParent,
.groupGroup {
  display: flex;
  justify-content: space-between;
}
.groupGroup {
  background-color: var(--white);
  width: 1036px;
  flex-direction: row;
  align-items: flex-end;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
}
.frameParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: var(--h21-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
