.child,
.ellipseIcon,
.inner,
.item,
.svg1Icon {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.child {
  height: 167.94%;
  width: 199.28%;
  top: -24.43%;
  right: -42.45%;
  bottom: -43.51%;
  left: -56.83%;
  display: none;
  opacity: 0.8;
}
.ellipseIcon,
.inner,
.item,
.svg1Icon {
  height: 5.38%;
  width: 38.76%;
  top: 8.54%;
  right: 49.16%;
  bottom: 86.08%;
  left: 12.08%;
}
.ellipseIcon,
.inner,
.item {
  height: 17.43%;
  width: 24.6%;
  top: 79.05%;
  right: 72.91%;
  bottom: 3.52%;
  left: 2.49%;
}
.ellipseIcon,
.inner {
  height: 27.06%;
  width: 38.14%;
  top: 77.96%;
  right: -4.17%;
  bottom: -5.02%;
  left: 66.03%;
}
.ellipseIcon {
  height: 36.66%;
  width: 51.68%;
  top: 0;
  right: -17.72%;
  bottom: 63.34%;
}
.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  overflow: hidden;
  cursor: pointer;
}
