.generellaKompetenser {
  position: relative;
  line-height: 134%;
}
.frameChild {
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--blue1);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.yourJob {
  position: relative;
  line-height: 163%;
}
.rectangleParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameInner,
.frameItem {
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--placeholder-grey);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.frameInner {
  background-color: var(--warning-red1);
}
.component69,
.frameDiv {
  flex-direction: row;
  justify-content: flex-start;
}
.frameDiv {
  display: none;
  align-items: center;
  gap: var(--gap-xs);
}
.component69 {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--button-text-size);
}
.generellaKompetenserParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}
.b,
.efterfrgan,
.kompetens {
  position: relative;
  line-height: 156%;
}
.kompetens {
  flex: 1;
}
.b,
.efterfrgan {
  display: inline-block;
  width: 345px;
}
.b {
  flex: 1;
  line-height: 163%;
}
.efterfrganParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frandring {
  position: relative;
  line-height: 156%;
  display: inline-block;
  width: 690px;
}
.kompetensParent,
.parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.kompetensParent {
  background-color: var(--background-grey);
  border-bottom: 1px solid var(--border);
  padding: var(--padding-2xs) 0;
  justify-content: flex-end;
}
.loremIpsum,
.na {
  flex: 1;
  position: relative;
  line-height: 163%;
}
.na {
  font-weight: 500;
  text-align: center;
}
.frameChild1,
.frameChild2 {
  align-self: stretch;
  position: relative;
  flex-shrink: 0;
}
.frameChild1 {
  background-color: var(--border);
  width: 1px;
}
.frameChild2 {
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--blue1);
  height: 26px;
}
.rectangleParent1,
.rectangleWrapper {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleWrapper {
  flex-direction: column;
  padding: var(--padding-2xs) 0;
}
.rectangleParent1 {
  align-self: stretch;
  flex-direction: row;
}
.component78Icon {
  position: relative;
  width: 44px;
  height: 24px;
  flex-shrink: 0;
}
.component78Wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs) 0;
  align-items: center;
  justify-content: center;
}
.component51 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
}
.na1 {
  flex: 1;
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.naWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
}
.frameChild3,
.frameChild4 {
  position: relative;
  background-color: var(--border);
  width: 1px;
  height: 42px;
  flex-shrink: 0;
}
.frameChild4 {
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--blue1);
  width: 146px;
  height: 26px;
}
.rectangleFrame,
.rectangleParent2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleFrame {
  flex-direction: column;
  padding: var(--padding-2xs) 0;
}
.rectangleParent2 {
  flex: 1;
  flex-direction: row;
}
.component511 {
  align-self: stretch;
  background-color: var(--background-grey);
  border-bottom: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-sm);
  align-items: center;
  justify-content: center;
}
.groupChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--placeholder-grey);
}
.groupDiv {
  position: relative;
  width: 133px;
  height: 26px;
  flex-shrink: 0;
}
.groupWrapper {
  position: absolute;
  top: 0;
  left: 1px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameChild13 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--border);
  width: 1px;
  height: 42px;
}
.frameParent1 {
  flex: 1;
  position: relative;
  height: 42px;
}
.component516 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-sm);
  align-items: center;
  justify-content: center;
}
.frameChild21,
.rectangleWrapper5 {
  position: relative;
  width: 92px;
  height: 26px;
  flex-shrink: 0;
}
.frameChild21 {
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--warning-red1);
  width: 16px;
}
.rectangleParent7 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameChild23 {
  position: relative;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--warning-red1);
  width: 54px;
  height: 26px;
  flex-shrink: 0;
}
.component51Parent {
  border-radius: var(--br-xs);
  overflow: hidden;
  align-items: flex-end;
  text-align: left;
}
.component51Parent,
.frameContainer,
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameContainer {
  border-radius: var(--br-xs);
  background-color: var(--white);
  border: 1px solid var(--border);
  overflow: hidden;
  align-items: flex-start;
  font-size: var(--preamble1-size);
}
.frameGroup {
  align-items: center;
  gap: var(--gap-xl);
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.page1Of {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.frameParent,
.groupParent {
  display: flex;
  justify-content: space-between;
}
.groupParent {
  align-self: stretch;
  background-color: var(--white);
  flex-direction: row;
  align-items: flex-end;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
}
.frameParent {
  flex: 1;
  width: 1036px;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  font-size: var(--h21-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
