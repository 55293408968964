.dittYrke {
  position: relative;
  line-height: 156%;
}
.component90frame299Child,
.component90frame299Item {
  position: relative;
  width: 87.54px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.component90frame299Item {
  width: 99.77px;
}
.component90frame299Inner,
.maskGroupIcon {
  position: relative;
  width: 68.51px;
  height: 80px;
  flex-shrink: 0;
  object-fit: cover;
}
.component90frame299Inner {
  width: 99.62px;
  display: none;
}
.component90frame299,
.dittYrkeParent {
  width: 140px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
}
.component90frame299 {
  border-radius: var(--br-md);
  background-color: var(--white);
  border: 12px solid var(--background-green1);
  height: 140px;
  overflow: hidden;
  padding: var(--padding-sm);
  justify-content: center;
}
.dittYrkeParent {
  padding: 0 0 var(--padding-2xl);
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameChild {
  flex: 1;
  position: relative;
  width: 39px;
  overflow: hidden;
}
.loremIpsumDolor {
  flex: 1;
  position: relative;
  line-height: 163%;
  font-weight: 500;
  display: inline-block;
  width: 230px;
}
.frameGroup,
.frameParent {
  display: flex;
  justify-content: flex-start;
}
.frameGroup {
  align-self: stretch;
  flex-direction: column;
  padding: var(--padding-3xl) 0 0;
  align-items: flex-start;
  text-align: left;
  font-size: var(--button-text-size);
}
.frameParent {
  position: absolute;
  top: 594px;
  left: 147px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
  font-family: var(--graph-text-121);
}
