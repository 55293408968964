.dinJobbframtidAnalysis {
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.groupIcon {
  position: relative;
  width: 122.7px;
  height: 24px;
  flex-shrink: 0;
}
.dinJobbframtidAnalysisParent {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameChild {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--blue1);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.dinFramtid {
  position: relative;
  line-height: 134%;
}
.frameParent {
  border-radius: var(--br-sm) var(--br-sm) 0 0;
  background-color: var(--chapter-2);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.viHarNu {
  flex: 1;
  position: relative;
  line-height: 163%;
  font-weight: 500;
}
.component76,
.viHarNuKommitTillSlutetAWrapper {
  border-radius: var(--br-sm);
  display: flex;
  align-items: center;
}
.viHarNuKommitTillSlutetAWrapper {
  align-self: stretch;
  border: 1px solid var(--border);
  flex-direction: row;
  padding: var(--padding-sm);
  justify-content: flex-start;
  text-align: left;
  font-size: var(--preamble1-size);
  color: var(--font-color1);
}
.component76 {
  width: 1036px;
  flex-direction: column;
  justify-content: center;
  font-size: var(--h1-size);
  color: var(--white);
}
.frameItem {
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--warning-red1);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.yourJob {
  position: relative;
  line-height: 163%;
}
.rectangleParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameChild1,
.frameInner,
.rectangleDiv {
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--yellow1);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.frameChild1,
.rectangleDiv {
  background-color: var(--blue1);
}
.frameChild1 {
  background-color: var(--placeholder-grey);
}
.frameDiv {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.component69,
.groupParent {
  display: flex;
  flex-direction: row;
}
.component69 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  color: var(--font-color1);
}
.groupParent {
  background-color: var(--white);
  width: 1036px;
  height: 35.64px;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: space-between;
}
.div,
.frameGroup {
  display: flex;
  flex-direction: column;
}
.frameGroup {
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
}
.div {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1933px;
  padding: var(--padding-4xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  cursor: pointer;
  text-align: center;
  font-size: var(--button-text-size);
  color: var(--placeholder-grey);
  font-family: var(--graph-text-121);
}
